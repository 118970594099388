<template>
  <v-container fluid>
    <br>
    <v-btn
      color="#aaa"
      class="ml-7 "
      text
      x-large
      dark
      @click="$router.go(-1)"
    >
      <v-icon
        left
        dark
      >
        mdi-arrow-left
      </v-icon>
      Back
    </v-btn>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-checkbox-marked-outline
      </v-icon> Check-In Details
    </h2>
    <br>
    <div v-if="subscriptionCheck('active')">
      <v-card
        shaped
        color="#CFD8DC"
      >
        <v-container
          v-if="isRecordDetailsFetched"
          fluid
        >
          <v-row justify="center">
            <v-col
              cols="12"
              md="6"
            >
              <v-card>
                <v-toolbar
                  color="#37474F"
                  dark
                >
                  <v-toolbar-title class="my-toolbar-style">
                    User Details
                  </v-toolbar-title>
                </v-toolbar>
                <v-container fluid>
                  <p class="my-heading-style">
                    Profile
                  </p>
                  <hr>
                  <br>
                  <p class="my-p-style">
                    Name:-
                    <font class="font-my-style">
                      {{ recordDetails.app_user.first_name }} {{ recordDetails.app_user.last_name }}
                    </font>
                  </p>
                  <p class="my-p-style">
                    Company:-
                    <font class="font-my-style">
                      {{ recordDetails.app_user.company.name }}
                    </font>
                  </p>
                  <p class="my-p-style">
                    Check-In Types:-
                    <font class="font-my-style">
                      {{ recordDetails.app_user.types | arrangePretty }}
                    </font>
                  </p>
                  <p class="my-p-style">
                    Category:-
                    <font
                      v-if="recordDetails.app_user.worker_category !== null"
                      class="font-my-style"
                    >
                      {{ recordDetails.app_user.worker_category.name }}
                    </font>
                    <font
                      v-else
                      class="font-my-style"
                    >
                      N/A
                    </font>
                  </p>
                  <p class="my-p-style">
                    High Risk Worker (HRW):-
                    <font :color="hrwStatusColor(recordDetails.app_user.high_risk_worker)">
                      {{ recordDetails.app_user.high_risk_worker | convertToString }}
                    </font>
                  </p>
                  <p class="my-p-style">
                    Work Hours:-
                    <font class="font-my-style">
                      {{ recordDetails.app_user.work_hours }}
                    </font>
                  </p>
                  <div>
                    <p class="my-heading-style">
                      Questionnaire
                    </p>
                    <hr>
                    <br>
                    <div
                      v-if="recordDetails.questionnaire.length === 0"
                      class="no-file-alert"
                    >
                      <h4>
                        No questionnaire found.
                      </h4>
                    </div>
                    <template v-for="(item, index) in recordDetails.questionnaire">
                      <div :key="'item' + index">
                        <template>
                          <p class="my-p-style">
                            {{ index + 1 }}. Question: <font class="font-my-style">
                              {{ item.question.question }}
                            </font>
                          </p>
                          <p class="my-p-style">
                            Answer: <font class="answer-my-style">
                              {{ item.answer }}
                            </font>
                            <font
                              class="status-style"
                              :color="getColor(item.question.deleted_at)"
                            >
                              {{ item.question.deleted_at | statusUpdate }}
                            </font>
                          </p>
                        </template>
                      </div>
                      <v-divider
                        v-if="index < recordDetails.questionnaire.length - 1"
                        :key="index"
                      />
                    </template>
                  </div>
                </v-container>
              </v-card>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-card>
                <v-toolbar
                  color="#37474F"
                  dark
                >
                  <v-toolbar-title class="my-toolbar-style">
                    Check-In Details
                  </v-toolbar-title>
                </v-toolbar>
                <v-container fluid>
                  <p class="my-heading-style">
                    Check-In
                  </p>
                  <hr>
                  <br>

                  <div v-if="recordDetails.type === 'contractor'">
                    <p class="my-p-style">
                      Project:-
                      <font class="font-my-style">
                        {{ recordDetails.project.name }}
                      </font>
                    </p>
                    <p class="my-p-style">
                      Check-In At:-
                      <font class="font-my-style">
                        {{ recordDetails.checkin_time | convertStringToLocalDatetime }}
                      </font>
                    </p>
                    <p class="my-p-style">
                      Check-In Gate:-
                      <font class="font-my-style">
                        {{ recordDetails.checkin_gate.name }}
                      </font>
                    </p>
                    <p class="my-p-style">
                      Check-In Type:-
                      <font class="font-my-style">
                        {{ recordDetails.type }}
                      </font>
                    </p>
                    <p class="my-p-style">
                      Contract Name:-
                      <font class="font-my-style">
                        {{ recordDetails.contract_details.contract.name }}
                      </font>
                    </p>
                    <p class="my-p-style">
                      Contract Items:- <font class="font-my-style">
                        {{ contractItemCounts }} Nos.
                      </font><br>
                      <font
                        v-for="(item, i) in recordDetails.contract_details.contract.budget_items"
                        :key="i"
                      >
                        <span class="budget-style">
                          <font
                            v-if="item.deleted_at === null"
                            class="font-my-style"
                          >
                            {{ item.name }}
                          </font>
                          <font v-else> <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }"><span
                              class="deleted-item"
                              v-bind="attrs"
                              v-on="on"
                            >{{ item.name }}</span></template>
                            <span>Deleted At:- <br>{{ item.deleted_at | convertStringToLocalDatetime }}</span>
                          </v-tooltip>
                          </font>
                        </span> |
                      </font>
                    </p>
                  </div>

                  <div v-if="recordDetails.type === 'visitor'">
                    <p class="my-p-style">
                      Project:-
                      <font class="font-my-style">
                        {{ recordDetails.project.name }}
                      </font>
                    </p>
                    <p class="my-p-style">
                      Check-In At:-
                      <font class="font-my-style">
                        {{ recordDetails.checkin_time | convertStringToLocalDatetime }}
                      </font>
                    </p>
                    <p class="my-p-style">
                      Check-In Gate:-
                      <font class="font-my-style">
                        {{ recordDetails.checkin_gate.name }}
                      </font>
                    </p>
                    <p class="my-p-style">
                      Check-In Type:-
                      <font class="font-my-style">
                        {{ recordDetails.type }}
                      </font>
                    </p>
                    <p class="my-p-style">
                      Visit Purpose:-
                      <font class="font-my-style">
                        {{ recordDetails.visit_details.purpose }}
                      </font>
                    </p>
                  </div>

                  <div v-if="recordDetails.type === 'staff'">
                    <p class="my-p-style">
                      Project:-
                      <font class="font-my-style">
                        {{ recordDetails.project.name }}
                      </font>
                    </p>
                    <p class="my-p-style">
                      Check-In At:-
                      <font class="font-my-style">
                        {{ recordDetails.checkin_time | convertStringToLocalDatetime }}
                      </font>
                    </p>
                    <p class="my-p-style">
                      Check-In Gate:-
                      <font class="font-my-style">
                        {{ recordDetails.checkin_gate.name }}
                      </font>
                    </p>
                    <p class="my-p-style">
                      Check-In Type:-
                      <font class="font-my-style">
                        {{ recordDetails.type | staffToSiteManager }}
                      </font>
                    </p>
                  </div>

                  <div v-if="recordDetails.type === 'worker'">
                    <p class="my-p-style">
                      Project:-
                      <font class="font-my-style">
                        {{ recordDetails.project.name }}
                      </font>
                    </p>
                    <p class="my-p-style">
                      Check-In Gate:-
                      <font
                        v-if="recordDetails.checkin_kiosk !== null && recordDetails.checkin_method_type === 'cost-shift'"
                      >
                        <v-icon small>
                          mdi-tablet-cellphone
                        </v-icon>
                        N/A
                      </font>
                      <font
                        v-if="recordDetails.checkin_kiosk !== null && recordDetails.checkin_method_type !== 'cost-shift'"
                      >
                        <v-icon small>
                          mdi-tablet-cellphone
                        </v-icon>
                        {{ recordDetails.checkin_kiosk.location_name }}
                      </font>
                      <font
                        v-if="recordDetails.checkin_kiosk === null && recordDetails.checkin_method_type === 'cost-shift'"
                      >
                        N/A
                      </font>
                      <font
                        v-if="recordDetails.checkin_kiosk === null && recordDetails.checkin_method_type !== 'cost-shift'"
                      >
                        {{ recordDetails.checkin_gate?.name }}
                      </font>
                    </p>
                    <p class="my-p-style">
                      Check-In At:-
                      <font class="font-my-style">
                        <v-icon
                          v-if="recordDetails.checkin_method_type === 'cost-shift'"
                          color="#2E7D32"
                          small
                        >
                          mdi-swap-horizontal-bold
                        </v-icon>
                        {{ recordDetails.checkin_time | convertStringToLocalDatetime }}
                      </font>
                    </p>
                    <div v-if="recordDetails.work_details.type === 'project-work'">
                      <p class="my-p-style">
                        Work Type:-
                        <font class="font-my-style">
                          Project Work*
                        </font>
                      </p>
                      <p class="my-p-style">
                        Budget Name:-
                        <font class="font-my-style">
                          {{ recordDetails.work_details.budget.name }}
                        </font>
                      </p>
                      <p class="my-p-style">
                        High Risk Work (HRW):-
                        <font :color="hrwStatusColor(recordDetails.work_details.budget.high_risk_work)">
                          {{ recordDetails.work_details.budget.high_risk_work | convertToString }}
                        </font>
                      </p>
                      <p class="my-p-style">
                        Description:-
                        <font
                          v-if="recordDetails.work_details.budget.description !== null"
                          class="font-my-style"
                        >
                          {{ recordDetails.work_details.budget.description }}
                        </font>
                        <font
                          v-else
                          class="font-my-style"
                        >
                          Nill
                        </font>
                      </p>
                      <p class="my-p-style">
                        Status:-
                        <font
                          v-if="recordDetails.work_details.budget.status !== null"
                          :color="getStatusColor(recordDetails.work_details.budget.status)"
                        >
                          {{ recordDetails.work_details.budget.status | statusFormat }}
                        </font>
                        <font
                          v-else
                          class="font-my-style"
                        >
                          N/A
                        </font>
                      </p>
                    </div>
                    <div v-if="recordDetails.work_details.type === 'project-issue'">
                      <p class="my-p-style">
                        Work Type:-
                        <font class="font-my-style">
                          Project Issue*
                        </font>
                      </p>
                      <p class="my-p-style">
                        Issue Title:-
                        <font class="font-my-style">
                          {{ recordDetails.work_details.issue.title }}
                        </font>
                      </p>
                      <p class="my-p-style">
                        High Risk Work (HRW):-
                        <font :color="hrwStatusColor(recordDetails.work_details.issue.title)">
                          {{ recordDetails.work_details.issue.title | convertToString }}
                        </font>
                      </p>
                      <p class="my-p-style">
                        Description:-
                        <font
                          v-if="recordDetails.work_details.issue.description !== null"
                          class="font-my-style"
                        >
                          {{ recordDetails.work_details.issue.description }}
                        </font>
                        <font
                          v-else
                          class="font-my-style"
                        >
                          Nill
                        </font>
                      </p>
                      <p class="my-p-style">
                        Status:-
                        <font
                          v-if="recordDetails.work_details.issue.status !== null"
                          :color="getStatusColor(recordDetails.work_details.issue.status)"
                        >
                          {{ recordDetails.work_details.issue.status | statusFormat }}
                        </font>
                        <font
                          v-else
                          class="font-my-style"
                        >
                          N/A
                        </font>
                      </p>
                    </div>
                  </div>

                  <p class="my-heading-style">
                    Check-Out
                  </p>
                  <hr>
                  <br>
                  <div>
                    <p class="my-p-style">
                      Check-Out At:-
                      <font
                        v-if="recordDetails.checkout_time !== null"
                        class="font-my-style"
                      >
                        {{ recordDetails.checkout_time | convertStringToLocalDatetime }}
                      </font>
                      <font
                        v-else
                        class="My-checkout-time"
                      >
                        Onsite*
                      </font>
                      <v-icon
                        v-if="recordDetails.checkout_method_type === 'cost-shift'"
                        color="#C62828"
                        small
                      >
                        mdi-swap-horizontal-bold
                      </v-icon>
                    </p>
                    <p class="my-p-style">
                      Check-Out Gate:-
                      <font
                        v-if="recordDetails.checkout_kiosk !== null && recordDetails.checkout_method_type === 'cost-shift'"
                      >
                        <v-icon small>
                          mdi-tablet-cellphone
                        </v-icon>
                        N/A
                      </font>
                      <font
                        v-if="recordDetails.checkout_kiosk !== null && recordDetails.checkout_method_type !== 'cost-shift'"
                      >
                        <v-icon small>
                          mdi-tablet-cellphone
                        </v-icon>
                        {{ recordDetails.checkout_kiosk.location_name }}
                      </font>
                      <font
                        v-if="recordDetails.checkout_kiosk === null && recordDetails.checkout_method_type === 'cost-shift' || recordDetails.manual_checkout === true"
                      >
                        N/A
                      </font>
                      <font
                        v-if="recordDetails.checkout_kiosk === null && recordDetails.checkout_method_type !== 'cost-shift'"
                      >
                        {{ recordDetails.checkout_gate?.name }}
                      </font>
                    </p>
                    <div v-if="recordDetails.work_details.type === 'project-issue'">
                      <p class="my-p-style">
                        Issue Status:-
                        <font
                          class="status"
                          :color="statusColor(recordDetails.work_details.status)"
                        >
                          {{ recordDetails.work_details.status }}
                        </font>
                      </p>
                      <p
                        v-if="recordDetails.work_details.status === 'pending'"
                        class="my-p-style"
                      >
                        Pending Reason:-
                        <font
                          v-if="recordDetails.work_details.pending_reason !== null"
                          class="font-my-style"
                        >
                          {{ recordDetails.work_details.pending_reason }}
                        </font>
                        <font
                          v-else
                          class="font-my-style"
                        >
                          N/A
                        </font>
                      </p>
                      <p
                        v-if="recordDetails.work_details.status === 'completed'"
                        class="my-p-style"
                      >
                        Root Cause:-
                        <font
                          v-if="recordDetails.work_details.root_cause !== null"
                          class="font-my-style"
                        >
                          {{ recordDetails.work_details.root_cause }}
                        </font>
                        <font
                          v-else
                          class="font-my-style"
                        >
                          N/A
                        </font>
                      </p>
                    </div>
                    <p class="my-p-style">
                      Total Work Hours:-
                      <font
                        v-if="recordDetails.total_hours !== null"
                        class="font-my-style"
                      >
                        {{ recordDetails.total_hours }} Hrs
                      </font>
                    </p>
                  </div>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <centre-spinner :loading="loading" />
    </div>
    <div v-else>
      <v-alert
        text
        prominent
        type="warning"
        color="#006883"
        border="left"
        class="alert-style"
      >
        Upgrade to Premium Version to view the CheckIns.
      </v-alert>
    </div>
  </v-container>
</template>
<script>
import moment from 'moment';
import spinner from 'src/views/dashboard/component/SpinnerCentre';
import global from 'src/mixins/global';
export default {
  name: 'CheckInRecordDetails',
  components: {
    'centre-spinner': spinner,
  },
  filters: {
    convertStringToLocalDatetime (stringDatetime) {
      return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
    },
    staffToSiteManager (val) {
        if (val === 'staff') {
          return 'Site Manager';
        }
        return val;
      },
    convertToString (val) {
      if (val === true) {
        return 'Yes*';
      }
      return 'No';
    },
    statusUpdate (val) {
      if (val !== null) {
        return 'Deleted';
      }
      return 'Active*';
    },
    arrangePretty (val) {
      return val.join(', ');
    },
    statusFormat (val) {
      if (val === 'active') {
        return 'Active*';
      } else if (val === 'completed') {
        return 'Completed*';
      } else if (val === 'pending') {
        return 'Pending';
      } else {
        return val;
      }
    },
  },
  mixins: [global],
  data () {
    return {
      loading: false,
    };
  },
  computed: {
    recordDetails () {
      return this.$store.getters['records/getRecordDetails'];
    },
    isRecordDetailsFetched () {
      if (Object.keys(this.recordDetails).length > 0) {
        return true;
      }
      return false;
    },
    contractItemCounts () {
      if (this.isRecordDetailsFetched === true) {
        const activeContractItems = this.recordDetails.contract_details.contract.budget_items.filter(item => item.deleted_at === null);
        const counts = activeContractItems.length;
        return counts;
      }
      return 0;
    },
  },
  async mounted () {
    this.loading = true;
    await this.fetchCheckInRecordDetails();
    this.loading = false;
  },
  methods: {
    async fetchCheckInRecordDetails () {
      const recordId = this.$route.params.recordId;
      await this.$store.dispatch('records/fetchRecordDetails', {
        record_id: recordId,
      });
    },
    statusColor (status) {
      if (status === 'completed') {
        return '#2E7D32';
      } else {
        return '#C62828';
      }
    },
    getStatusColor (val) {
      if (val === 'active') {
        return '#2E7D32';
      } else if (val === 'pending') {
        return '#C62828';
      } else if (val === 'completed') {
        return '#FF3700';
      } else {
        return '#37474F';
      }
    },
    hrwStatusColor (status) {
      if (status === true) {
        return '#C62828';
      } else {
        return '#2E7D32';
      }
    },
    getColor (val) {
      if (val !== null) {
        return '#C62828';
      }
      return '#2E7D32';
    },
  },
};
</script>
<style scoped>
.title-style {
  color: #37474F;
}

.icon-style {
  color: #37474F;
  font-size: 45px;
}

.font-my-style {
  font-weight: bold;
  font-size: 15px;
  color: #37474F;
  text-transform: capitalize;
}

.answer-my-style {
  font-weight: bold;
  font-size: 15px;
  color: #FF3700;
  text-transform: capitalize;
}

.my-card-style {
  background-color: #ECEFF1;
}

.my-p-style {
  font-size: 16px;
  font-weight: bold;
  color: #546E7A;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.my-heading-style {
  font-size: 17px;
  font-weight: bold;
  color: #263238;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.my-toolbar-style {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

.status {
  font-weight: bold;
  font-size: 15px;
  text-transform: capitalize;
}

.My-checkout-time {
  color: #2E7D32;
  font-weight: bold;
}

.status-style {
  float: right;
  font-weight: bold;
  font-family: 'Times New Roman', Times, serif;
}

.no-file-alert {
  color: #C62828;
  font-size: 18px;
  font-family: 'Times New Roman', Times, serif;
  margin-top: 15px;
}

.item-separate {
  color: #FF3700;
  font-weight: bolder;
}

.budget-style {
  font-weight: 600;
  text-transform: capitalize;
}

.progress-value {
  color: #2E7D32;
}

.deleted-item {
  color: #C62828;
  text-decoration: line-through;
}

.my-back-btn-style {
  margin-top: -20px;
  margin-bottom: 10px;
  margin-left: -20px !important;
}

@media (max-width: 960px) {
  .my-back-btn-style {
    margin-bottom: 0px;
    margin-left: 0px !important;
  }
}

.premium-style {
  font-size: 25px;
  color: #006883 !important;
  font-weight: bold;
}

.premium-container {
  background-color: #ffffff !important;
  border-radius: 50%;
}
</style>
